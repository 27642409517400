import './index.css';

import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import type { RunviewBOConfig } from '~/bo-client-base/src';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

import { PATHNAME_PREFIX } from '~/config/constant';
import initI18n from '~/config/i18n';

import acl from './config/acl.json';
import { createRunviewBO } from '~/bo-client-base/src';
const UsersPage = React.lazy(() => import('~/features/users/users'));
const PlansPage = React.lazy(() => import('~/features/payments/plans'));
const OffersPage = React.lazy(() => import('~/features/payments/offers'));
const VouchersPage = React.lazy(() => import('~/features/payments/vouchers'));
const ClassificationsPage = React.lazy(
  () => import('~/features/audit-rules/classifications'),
);
const LabelsPage = React.lazy(() => import('~/features/audit-rules/labels'));
const AccountTypesPage = React.lazy(
  () => import('~/features/configuration/account-types'),
);
const MetadatasPage = React.lazy(
  () => import('~/features/audit-rules/metadatas'),
);
const ArAccessPage = React.lazy(
  () => import('~/features/audit-rules/ar-access'),
);
const RolesPage = React.lazy(() => import('~/features/configuration/roles'));
const FeaturesPage = React.lazy(
  () => import('~/features/configuration/features'),
);
const SalesConfigurationsPage = React.lazy(
  () => import('~/features/configuration/sales'),
);
const KeywordsPage = React.lazy(
  () => import('~/features/audit-rules/keywords'),
);
const AccountsPage = React.lazy(() => import('~/features/users/accounts'));
const AnalysisPage = React.lazy(() => import('~/features/users/analyses'));

const SSOAuthPage = React.lazy(() => import('~/features/sso/'));

const container = document.getElementById('root')!;
const root = createRoot(container);

const config: RunviewBOConfig = {
  menuType: 'folder',
  features: [
    {
      key: 'payments',
      title: 'Paiements',
      icon: <PaidOutlinedIcon />,
      features: [
        {
          key: 'offers',
          title: 'Offres',
          feature: <OffersPage />,
        },
        {
          key: 'plans',
          title: 'Plans',
          feature: <PlansPage />,
        },
        {
          key: 'vouchers',
          title: 'Couponing',
          feature: <VouchersPage />,
        },
        /*{
          key: 'invoices',
          title: 'Paiements et factures',
          feature: <pre>Paiements et factures</pre>,
        },*/
      ],
    },
    {
      key: 'audit-rules',
      title: 'Audit rules',
      icon: <GavelOutlinedIcon />,
      features: [
        {
          key: 'metadata',
          title: 'Métadonnées',
          feature: <MetadatasPage />,
        },
        {
          key: 'ar-access',
          title: 'Accès aux ARs',
          feature: <ArAccessPage />,
        },
        {
          key: 'classifications',
          title: 'Classifications',
          feature: <ClassificationsPage />,
        },
        {
          key: 'labels',
          title: 'Labels',
          feature: <LabelsPage />,
        },
        {
          key: 'keywords',
          title: 'Mots clés',
          feature: <KeywordsPage />,
        },
      ],
    },
    {
      key: 'users',
      title: 'Utilisateurs',
      icon: <SupervisedUserCircleOutlinedIcon />,
      features: [
        {
          key: 'users',
          title: 'Utilisateurs',
          feature: <UsersPage />,
        },
        {
          key: 'accounts',
          title: 'Comptes',
          feature: <AccountsPage />,
        },
        {
          key: 'analyses',
          title: 'Analyses',
          feature: <AnalysisPage />,
        },
      /*  {
          key: 'reports',
          title: 'Rapports',
          feature: <pre>TODO: Rapports</pre>,
        },*/
      ],
    },
    {
      key: 'configuration',
      title: 'Configuration',
      icon: <SettingsIcon />,
      features: [
        {
          key: 'features',
          title: 'Fonctionnalités',
          feature: <FeaturesPage />,
        },
        {
          key: 'account-types',
          title: 'Types de compte',
          feature: <AccountTypesPage />,
        },
        {
          key: 'roles',
          title: 'Rôles',
          feature: <RolesPage />,
        },
        {
          key: 'sales',
          title: 'Sales',
          feature: <SalesConfigurationsPage />,
        },
      ],
    },
    {
      key: 'sso',
      title: 'Authentification',
      icon: <AdminPanelSettingsIcon />,
      features: [
        {
          key: 'sso-link',
          title: 'Voir le site public',
          feature: <SSOAuthPage />,
        },
      ],
    },
  ],
  defaultFeature: ['users', 'analyses'],
  pathnamePrefix: PATHNAME_PREFIX,
  loginPageTitle: 'Connexion à BO Analytics by ComptaSecure',
  roles: ['admin', 'operations', 'marketing', 'customersuccess'],
  reactStrictMode: false,
  acl,
};

const RunviewBO = () => createRunviewBO(config);

initI18n();

root.render(<RunviewBO />);

import i18next from 'i18next';

import authenticationTranslations from '~/locales/extended/authentication.json';
import fr from '~/locales/fr.json';

const DEFAULT_LANGUAGE = 'fr';
const DEFAULT_NS = 'translation';

/*
 * Map of bo-commons namespaces => translation keys to extend bo-commons labels (ex : roles).
 */
const EXTENDED_BO_COMMONS_LABELS = new Map<string, any>([
  [
    '@runview/bo-client-base/services/authentication',
    authenticationTranslations,
  ],
]);

const initI18n = () => {
  i18next.init({
    compatibilityJSON: 'v3',
    interpolation: { escapeValue: false }, // React already does escaping
    lng: DEFAULT_LANGUAGE, // default language to use
    fallbackLng: DEFAULT_LANGUAGE,
    ns: [DEFAULT_NS],
    defaultNS: DEFAULT_NS,

    debug: false,
    resources: {
      fr: {
        translation: fr,
      },
    },
  });

  // Extend bo-commons labels for each namespace
 EXTENDED_BO_COMMONS_LABELS.forEach((translationKeys, namespace) => {
    Object.keys(translationKeys).forEach((language) => {
      !i18next.hasResourceBundle(language, namespace) &&
        i18next.addResources(language, namespace, translationKeys[language]);
    });
  });
};

export default initI18n;

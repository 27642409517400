import i18n from 'i18next';
import * as React from 'react';
import { initReactI18next, useTranslation } from 'react-i18next';

export const initI18n = () => {
  void i18n.use(initReactI18next).init({
    resources: {},
    lng: 'fr', // if you're using a language detector, do not define the lng option
    fallbackLng: 'fr',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
};

type TranslationResources = {
  [lng: string]: {
    [textKey: string]: string;
  };
};

export const useI18nFor = (
  featureName: string,
  translations: TranslationResources,
) => {
  const { i18n } = useTranslation();

  React.useEffect(() => {
    void i18n.changeLanguage('fr');
  }, [i18n]);

  React.useEffect(() => {
    Object.keys(translations).forEach((lng) => {
      !i18n.hasResourceBundle(lng, featureName) &&
        i18n.addResources(lng, featureName, translations[lng]);
    });
  }, [featureName, i18n, translations]);
};

initI18n();

export default i18n;

import { AuthenticationResult } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import * as React from 'react';

import { useAppRoles } from '~/bo-client-base/src/services/authentication/use-app-roles';
import { loginRequest } from '~/bo-client-base/src/services/azure-ad/config';
import { useConfig } from '~/bo-client-base/src/services/config';
import { getLoginPopupGeometry } from '~/bo-client-base/src/services/geometry';
import { useI18nFor } from '~/bo-client-base/src/services/i18n';

import translations from './translations.json';

export type UseAuthenticationHookReturnType = {
  isAuthenticated: boolean;
  isAuthorized: boolean;
  username: string;
  name: string;
  roles: string[];
  login: () => void;
  logout: () => void;
  acquireTokenSilentForCurrentUser: () => Promise<AuthenticationResult>;
  hasAccess: (scope: string) => boolean;
};

const isPlainObject = (value: any) =>
  value === Object(value) && !Array.isArray(value);

export const useAuthentication = (): UseAuthenticationHookReturnType => {
  useI18nFor('@runview/bo-client-base/services/authentication', translations);

  const { accounts, instance } = useMsal();

  const isAuthenticated = useIsAuthenticated(accounts[0]);

  const { appRoles, keepOnlyKnownRoles } = useAppRoles();

  const [userRoles, setUserRoles] = React.useState<string[]>(
    keepOnlyKnownRoles((accounts[0]?.idTokenClaims as any)?.roles ?? []),
  );

  const [isAuthorized, setIsAuthorized] = React.useState(() =>
    Object.values(appRoles).some((role) => userRoles.includes(role)),
  );

  const username = accounts[0]?.username;

  const name = accounts[0]?.name ?? '';

  const login = React.useCallback(
    async () =>
      instance.loginPopup({
        ...loginRequest,
        popupWindowAttributes: getLoginPopupGeometry(),
      }),
    [instance],
  );

  const logout = React.useCallback(() => {
    window.localStorage.clear();
    window.location.reload();
  }, []);

  const acquireTokenSilentForCurrentUser = React.useCallback(
    async () =>
      await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      }),
    [accounts, instance],
  );

  const acquireTokenSilentOrRedirect = React.useCallback(() => {
    let response;

    try {
      response = acquireTokenSilentForCurrentUser();
    } catch (error) {
      // logout & redirect user to login page
      logout();
    }

    return response as Promise<AuthenticationResult>;
  }, [acquireTokenSilentForCurrentUser, logout]);

  const loadUserRoles = React.useCallback(async () => {
    try {
      const tokenResponse = await acquireTokenSilentForCurrentUser();
      return (tokenResponse?.account?.idTokenClaims as any).roles as string[];
    } catch {
      logout();
    }

    return [];
  }, [acquireTokenSilentForCurrentUser, logout]);

  React.useLayoutEffect(() => {
    const currentAccount = accounts[0];

    if (currentAccount) {
      (async () => {
        const userRoles = await loadUserRoles();
        userRoles && setUserRoles(keepOnlyKnownRoles(userRoles));
      })();
    }
  }, [accounts, instance, keepOnlyKnownRoles, loadUserRoles]);

  React.useLayoutEffect(() => {
    const shouldAuthorize = Object.values(appRoles).some((authorizedRole) =>
      userRoles.includes(authorizedRole),
    );

    setIsAuthorized(shouldAuthorize);
  }, [appRoles, userRoles]);

  const { acl } = useConfig();

  const { accessRights } = acl;

  const hasAccess = React.useCallback(
    (scope: string) => {
      const selectedAccessRights = scope
        .split('.')
        .reduce((acc, currentValue) => acc?.[currentValue], accessRights);

      const checkAccessRights = (value: any): boolean => {
        if (value === undefined) {
          return false;
        }

        if (value === '*') {
          return true;
        }

        if (Array.isArray(value)) {
          return value.some((role) => userRoles.includes(appRoles[role]));
        }

        if (isPlainObject(value) && !!value.default) {
          return checkAccessRights(value.default);
        }

        return false;
      };

      return checkAccessRights(selectedAccessRights);
    },
    [accessRights, appRoles, userRoles],
  );

  return {
    isAuthenticated,
    isAuthorized,
    roles: userRoles,
    username,
    name,
    login,
    logout,
    acquireTokenSilentForCurrentUser: acquireTokenSilentOrRedirect,
    hasAccess,
  };
};

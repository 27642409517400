import * as React from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';

import { FlatMenu, FolderMenu, MenuItem } from '~/bo-client-base/src/components/menu';
import { Feature, FeatureFolder, useConfig } from '~/bo-client-base/src/services/config';

const createMenu = (
  menuType: string,
  pathname: string,
  pathnamePrefix: string,
  features: Feature[] | FeatureFolder[],
  navigate: NavigateFunction,
): [React.ReactElement, string | undefined] | never => {
  if (menuType === 'flat') {
    const selectedFeatureKey = pathname.split('/')[2];

    const selectedFeatureTitle = (features as Feature[]).find(
      (item: Feature) => item.key === selectedFeatureKey,
    )?.title;

    const menu = features.map(
      (feature): MenuItem => ({
        key: feature.key,
        title: feature.title,
        icon: feature.icon,
        onSelect: () => navigate(`/${pathnamePrefix}/${feature.key}`),
      }),
    );

    return [
      <FlatMenu menu={menu} selectedItemKey={selectedFeatureKey} />,
      selectedFeatureTitle,
    ];
  }

  if (menuType === 'folder') {
    const pathnameArr = pathname.split('/');

    const selectedFeature = [pathnameArr[2], pathnameArr[3]] as [
      string,
      string,
    ];

    const menu = (features as FeatureFolder[]).map((featureFolder) => ({
      key: featureFolder.key,
      title: featureFolder.title,
      icon: featureFolder.icon,
      items: featureFolder.features.map((feature) => ({
        key: feature.key,
        title: feature.title,
        onSelect: (folderKey: string, itemKey: string) => {
          navigate(`/${pathnamePrefix}/${folderKey}/${itemKey}`);
        },
      })),
    }));

    const selectedFolder = (features as FeatureFolder[]).find(
      (featureFolder) => featureFolder.key === selectedFeature[0],
    );

    const selectedItem = selectedFolder?.features.find(
      (feature) => feature.key === selectedFeature[1],
    );

    const selectedFeatureTitle = selectedItem?.title;

    return [
      <FolderMenu menu={menu} selectedItem={selectedFeature} />,
      selectedFeatureTitle,
    ];
  }

  throw new Error('menuType not recognized');
};

export const useMenu = (): [React.ReactElement, string | undefined] => {
  const { menuType, features, pathnamePrefix } = useConfig();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  return React.useMemo(
    () => createMenu(menuType, pathname, pathnamePrefix, features, navigate),
    [features, menuType, pathname, pathnamePrefix, navigate],
  );
};

import 'twin.macro';
import 'react-toastify/dist/ReactToastify.css';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import comptaSecureLogo from '~/bo-client-base/src/assets/svg/comptasecure-logo.svg';
import { UserAccount } from '~/bo-client-base/src/components/user-account';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme, fullClose: boolean): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: fullClose ? 0 : `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: fullClose ? 0 : `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'fullClose',
})<{ fullClose?: boolean }>(({ theme, open, fullClose = false }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme, fullClose),
    '& .MuiDrawer-paper': closedMixin(theme, fullClose),
  }),
}));

type AppLayoutProps = {
  menuType?: 'flat' | 'folder';
  menuElement?: any;
  title?: string;
};

export const AppLayout = (props: AppLayoutProps) => {
  const { menuType, title, menuElement } = props;

  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const fullClose = menuType === 'folder';

  return (
    <Box tw='flex h-[100vh] w-[100vw] bg-gray-50'>
      <AppBar open={open} position='fixed'>
        <Toolbar>
          <IconButton
            aria-label='open drawer'
            color='inherit'
            edge='start'
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Typography noWrap component='div' tw='flex-grow' variant='h6'>
            {title}
          </Typography>
          <UserAccount />
        </Toolbar>
      </AppBar>
      <Drawer fullClose={fullClose} open={open} variant='permanent'>
        <DrawerHeader>
          <div tw='flex justify-center w-full'>
            <img alt='ComptaSecure logo' src={comptaSecureLogo} width={150} />
          </div>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        {menuElement}
      </Drawer>
      <Box component='main' tw='p-4 h-[calc(100% - 4rem)] w-full'>
        <DrawerHeader />
        <React.Suspense fallback={<Typography>Loading...</Typography>}>
          <Outlet />
        </React.Suspense>
        <ToastContainer
          autoClose={5000}
          theme='light'
          toastStyle={{
            width: 512,
            float: 'right',
          }}
        />
      </Box>
    </Box>
  );
};

import 'twin.macro';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import microsoftLogo from '~/bo-client-base/src/assets/svg/microsoft-logo-white.svg';
import comptaSecureLogo from '~/bo-client-base/src/assets/svg/comptasecure-logo.svg';
import { useAuthentication } from '~/bo-client-base/src/services/authentication';
import { useI18nFor } from '~/bo-client-base/src/services/i18n';

import translations from './translations.json';

const ResponsiveLogo = () => (
  <div tw='flex justify-center w-full'>
    <img alt='ComptaSecure logo' src={comptaSecureLogo} tw='mb-8 w-[171px]' />
  </div>
);

type AzureADAuthButtonProps = {
  onClick: React.MouseEventHandler;
};

const AzureADAuthButton = (props: AzureADAuthButtonProps) => {
  const { onClick } = props;
  const { t } = useTranslation('@runview/bo-client-base/features/login');

  return (
    <Button
      aria-label='login'
      size='large'
      startIcon={
        <Avatar
          src={microsoftLogo}
          tw='mr-1 w-[1.4rem] h-[1.4rem]'
          variant='square'
        />
      }
      variant='contained'
      onClick={onClick}
    >
      {t('login-button')}
    </Button>
  );
};

export const LoadingBackdrop = () => (
  <div
    data-testid='loading-backdrop'
    tw='flex absolute w-full h-full bg-white/30 backdrop-blur-sm'
  >
    <div tw='hidden flex-1 h-full lg:block' />
    <div tw='flex flex-1 justify-center items-center h-full'>
      <CircularProgress color='primary' role='progressbar' />
    </div>
  </div>
);

type LoginProps = {
  title?: string;
};

const Login = (props: LoginProps) => {
  const { title } = props;

  useI18nFor('@runview/bo-client-base/features/login', translations);

  const { t } = useTranslation('@runview/bo-client-base/features/login');

  const [showBackdrop, setShowBackdrop] = React.useState(false);

  const timeout = React.useRef<number | undefined>();

  const { login, isAuthenticated, isAuthorized } = useAuthentication();

  const authenticateUser = async () => {
    setShowBackdrop(true);

    try {
      await login();
    } catch (e) {
      alert(e);
    } finally {
      timeout.current = window.setTimeout(() => {
        setShowBackdrop(false);
      }, 0);
    }
  };

  React.useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);

  const shouldDisplayForbiddenAccessMessage = isAuthenticated && !isAuthorized;

  return (
    <div tw='flex w-screen h-screen bg-gradient-to-r from-green-500 via-green-200 to-green-500'>
      <div tw='hidden flex-1 h-full bg-cover lg:block bg-login' />
      <Paper
        elevation={24}
        tw='flex flex-col flex-1 justify-center items-center h-full bg-white rounded-none opacity-90'
      >
        <Stack spacing={4} tw='mb-8'>
          <ResponsiveLogo />
          <Typography tw='text-center'>{title}</Typography>
          <AzureADAuthButton onClick={authenticateUser} />
        </Stack>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={shouldDisplayForbiddenAccessMessage}
        >
          <Alert severity='warning'>
            <AlertTitle> {t('forbidden_access-title')}</AlertTitle>
            {t('forbidden_access-message')}
          </Alert>
        </Snackbar>
      </Paper>
      {showBackdrop && <LoadingBackdrop />}
    </div>
  );
};

export default Login;

import { PopupWindowAttributes } from "@azure/msal-browser/dist/request/PopupWindowAttributes";

export const getLoginPopupGeometry = (): PopupWindowAttributes => {
  const PADDING = 128;
  const TOP_OFFSET = 16;

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  const popupWidth = screenWidth / 2 - 2 * PADDING;
  const popupHeight = screenHeight - 2 * PADDING;

  return {
    popupPosition: {
      top: PADDING + TOP_OFFSET,
      left: PADDING,
    },
    popupSize: {
      width: popupWidth,
      height: popupHeight,
    },
  };
};

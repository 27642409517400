import Logout from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthentication } from '~/bo-client-base/src/services/authentication';

const getInitials = (username: string) => {
  try {
    const [firstName, lastName] = username.split('@')[0].split('.');
    return `${firstName?.[0]?.toUpperCase() ?? '?'}${
      lastName?.[0]?.toUpperCase() ?? ''
    }`;
  } catch {
    return '?';
  }
};

export const UserAccount = () => {
  const { t } = useTranslation(
    '@runview/bo-client-base/services/authentication',
  );

  const { logout, username, name, roles } = useAuthentication();

  return (
    <div className='flex'>
      <Avatar className='mr-2 bg-amber-400'>{getInitials(username)}</Avatar>
      <div>
        <Typography className='font-semibold' fontSize={16}>
          {name}
        </Typography>
        <Typography className='flex items-center' fontSize={14}>
          <span className='mr-1 truncate'>
            <Tooltip title={roles.map(t).join(', ')}>
              <span className='mr-1 capitalize hover:cursor-pointer'>
                {t(roles[0])}
              </span>
            </Tooltip>
            &middot;{' '}
          </span>
          <span
            className='mr-1 hover:underline truncate hover:cursor-pointer'
            onClick={logout}
          >
            Se déconnecter
          </span>
          <Logout style={{ fontSize: 16 }} />
        </Typography>
      </div>
    </div>
  );
};

import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AppLayout } from '~/bo-client-base/src/components/app-layout';
import Login from '~/bo-client-base/src/features/login';
import { useAuthentication } from '~/bo-client-base/src/services/authentication';
import { Feature, FeatureFolder, useConfig } from '~/bo-client-base/src/services/config';
import { useMenu } from '~/bo-client-base/src/services/menu';

type AppRouteProps = {
  isAuthorized: boolean;
};

const AppRoutes = (props: AppRouteProps) => {
  const { isAuthorized } = props;

  const { menuType, defaultFeature, features, loginPageTitle, pathnamePrefix } =
    useConfig();

  const { hasAccess } = useAuthentication();

  const defaultPathname = isAuthorized ? `/${pathnamePrefix}` : '/login';

  const computedDefaultFeature =
    menuType === 'flat'
      ? (defaultFeature as string)
      : `${defaultFeature[0]}/${defaultFeature[1]}`;

  const redirectToMainFeature = (
    <Route
      // @ts-ignore
      index
      element={<Navigate replace to={computedDefaultFeature} />}
      path='*'
    />
  );

  if (!menuType || !['flat', 'folder'].includes(menuType)) {
    throw new Error(
      'Cannot create routes because menuType has not been recognized.',
    );
  }

  let featureRoutes = [] as any[];

  if (menuType === 'flat') {
    featureRoutes = (features as Feature[])
      .filter((feature) => hasAccess(feature.key))
      .map((feature) => (
        <Route
          key={feature.key}
          element={feature.feature}
          path={`${feature.key}/*`}
        />
      ));
  }

  if (menuType === 'folder') {
    featureRoutes = (features as FeatureFolder[])
      .filter((featureFolder) => hasAccess(featureFolder.key))
      .map((featureFolder) => {
        return featureFolder.features
          .filter((feature) =>
            hasAccess([featureFolder.key, feature.key].join('.')),
          )
          .map((feature: Feature) => (
            <Route
              key={feature.key}
              element={feature.feature}
              path={`${featureFolder.key}/${feature.key}/*`}
            />
          ));
      });
  }

  const [menuElement, selectedFeatureTitle] = useMenu();

  const layout = React.useMemo(
    () => (
      <AppLayout
        menuElement={menuElement}
        menuType={menuType}
        title={selectedFeatureTitle}
      />
    ),
    [menuElement, menuType, selectedFeatureTitle],
  );

  const mainRoute = (
    <Route element={layout} path={`/${pathnamePrefix}`}>
      {featureRoutes}
      {redirectToMainFeature}
    </Route>
  );

  const loginRoute = (
    <Route element={<Login title={loginPageTitle} />} path='/login' />
  );

  const redirectRoute = (
    <Route element={<Navigate replace to={defaultPathname} />} path='*' />
  );

  return (
    <Routes>
      {isAuthorized ? mainRoute : loginRoute}
      {redirectRoute}
    </Routes>
  );
};

export { AppRoutes };

import * as React from 'react';

export type Feature = {
  key: string;
  title: string;
  icon?: React.ReactElement;
  feature: React.ReactElement;
};

export type FeatureFolder = {
  key: string;
  title: string;
  icon?: React.ReactElement;
  features: Omit<Feature, 'icon'>[];
};

export type RunviewBOConfig = {
  menuType?: 'flat' | 'folder';
  features?: Feature[] | FeatureFolder[];
  defaultFeature?: string | [string, string];
  pathnamePrefix?: string;
  loginPageTitle?: string;
  roles?: string[];
  acl?: {
    roles: string[];
    accessRights: any;
  };
  reactStrictMode?: boolean;
};

const ConfigContext = React.createContext<RunviewBOConfig>(
  {} as RunviewBOConfig,
);

export const ConfigProvider = ConfigContext.Provider;

export const useConfig = (): Required<RunviewBOConfig> => {
  const userConfig = React.useContext(ConfigContext);

  return React.useMemo(
    () => ({
      menuType: 'flat',
      features: [],
      defaultFeature: '',
      pathnamePrefix: 'admin',
      loginPageTitle: 'Connexion',
      roles: [],
      acl: {
        roles: [],
        accessRights: {},
      },
      reactStrictMode: true,
      ...userConfig,
    }),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );
};

import './index.css';

import { MsalProvider } from '@azure/msal-react';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { useAuthentication } from '~/bo-client-base/src/services/authentication';
import msalInstance from '~/bo-client-base/src/services/azure-ad/msal';
import { ConfigProvider, RunviewBOConfig } from '~/bo-client-base/src/services/config';
import { AppRoutes } from '~/bo-client-base/src/services/routes';

import { theme } from './theme';

const ThirdPartyProviders = (props: { children: React.ReactNode }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>{props.children}</BrowserRouter>
      </ThemeProvider>
    </MsalProvider>
  );
};

const BaseApp = () => {
  const { isAuthorized } = useAuthentication();

  return <AppRoutes isAuthorized={isAuthorized} />;
};

const App = () => {
  return (
    <ThirdPartyProviders>
      <BaseApp />
    </ThirdPartyProviders>
  );
};

const createRunviewBO = (config: RunviewBOConfig) => {
  const { reactStrictMode } = config;

  const app = (
    <StyledEngineProvider injectFirst>
      <ConfigProvider value={config}>
        <App />
      </ConfigProvider>
    </StyledEngineProvider>
  );

  if (reactStrictMode) {
    return <React.StrictMode>{app}</React.StrictMode>;
  }

  return app;
};

export type { RunviewBOConfig };

export { createRunviewBO };

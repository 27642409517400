/* istanbul ignore file */

import { teal } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: teal[500], // closest material color to brand color (#00a99d)
    },
  },
});

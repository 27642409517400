import * as React from 'react';

import { useConfig } from '~/bo-client-base/src/services/config';

type UseAppRolesHookReturnType = {
  appRoles: Record<string, string>;
  keepOnlyKnownRoles: (roles: string[]) => string[];
};

export const useAppRoles = (): UseAppRolesHookReturnType => {
  const config = useConfig();

  const { roles } = config;

  const getRoleEnvKey = (role: string) =>
    `REACT_APP_ROLE_${role.toUpperCase()}`;

  React.useEffect(() => {
    const checkForEmptyRoles = () => {
      const emptyRoles = roles
        .map(getRoleEnvKey)
        .filter((roleEnvName) => !process.env[roleEnvName]);

      emptyRoles.length &&
        console.error(
          `The following environment variables for role definitions are empty:`,
          emptyRoles.join(','),
        );
    };

    checkForEmptyRoles();
  }, [roles]);

  const appRolesEntries = React.useMemo(
    () => roles.map((role) => [role, process.env[getRoleEnvKey(role)]!]),
    [roles],
  );

  const appRoles = React.useMemo(
    () => Object.fromEntries(appRolesEntries),
    [appRolesEntries],
  );

  const keepOnlyKnownRoles = React.useCallback(
    (fromRoles: string[]) => {
      const appRoleValues = Object.values(appRoles);

      return fromRoles.filter((role) => appRoleValues.includes(role));
    },
    [appRoles],
  );

  return {
    appRoles,
    keepOnlyKnownRoles,
  };
};

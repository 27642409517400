import 'twin.macro';

import Folder from '@mui/icons-material/Folder';
import FolderOpen from '@mui/icons-material/FolderOpen';
import { useTheme } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';

import { useAuthentication } from '~/bo-client-base/src/services/authentication';

export type MenuItem = {
  key: string;
  title: string;
  icon?: React.ReactElement;
  onSelect?: (...path: string[]) => void;
};

export type MenuNode = {
  key: string;
  title: string;
  icon?: React.ReactElement;
  items?: MenuItem[];
};

type FlatMenuProps = {
  menu: MenuItem[];
  selectedItemKey?: string;
};

export const FlatMenu = ({ menu, selectedItemKey }: FlatMenuProps) => {
  const theme = useTheme();

  const { hasAccess } = useAuthentication();

  return (
    <List>
      {menu
        .filter((item) => hasAccess(item.key))
        .map((item) => {
          return (
            <ListItem
              key={item.key}
              disablePadding
              onClick={() => {
                if (item.key !== selectedItemKey) {
                  item.onSelect?.(item.key);
                }
              }}
            >
              <ListItemButton selected={item.key === selectedItemKey}>
                <ListItemIcon
                  sx={{
                    ...(item.key === selectedItemKey && {
                      color: theme.palette.primary.dark,
                    }),
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.title}
                  primaryTypographyProps={{
                    ...(item.key === selectedItemKey && {
                      fontWeight: 'bold',
                      color: theme.palette.primary.dark,
                    }),
                  }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
    </List>
  );
};

type TreeMenuProps = {
  menu: MenuNode[];
  selectedItem?: [string, string];
};

export const FolderMenu = ({ menu, selectedItem }: TreeMenuProps) => {
  const { hasAccess } = useAuthentication();

  const ellipsisStyles = React.useMemo(
    () => ({
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap' as any,
    }),
    [],
  );

  const theme = useTheme();

  const [openFolders, setOpenFolders] = React.useState<string[]>([]);

  const openFolder = React.useCallback(
    (folderKey: string) => {
      if (!selectedItem) {
        return setOpenFolders([folderKey]);
      }

      const [selectedFolderKey] = selectedItem;

      if (selectedFolderKey !== folderKey) {
        setOpenFolders([selectedFolderKey, folderKey]);
      } else {
        setOpenFolders([selectedFolderKey]);
      }
    },
    [selectedItem],
  );

  const closeFolder = React.useCallback((folderKey: string) => {
    setOpenFolders((openFolders) =>
      openFolders.filter((key) => key !== folderKey),
    );
  }, []);

  const onItemSelection = (folderKey: string, itemKey: string) => {
    setOpenFolders([folderKey]);
  };

  React.useEffect(() => {
    if (selectedItem) {
      openFolder(selectedItem[0]);
    }
  }, [selectedItem, openFolder]);

  return (
    <List disablePadding tw='overflow-hidden w-[240px]'>
      {menu
        .filter((folder) => hasAccess(folder.key))
        .map((folder) => (
          <React.Fragment key={folder.key}>
            {!openFolders.includes(folder.key) ? (
              <ListItem
                key={folder.key}
                disablePadding
                tw='border-t-4 border-indigo-500'
                onClick={() => {
                  openFolder(folder.key);
                }}
              >
                <ListItemButton>
                  <ListItemIcon>{folder.icon ?? <Folder />}</ListItemIcon>
                  <ListItemText
                    primary={folder.title}
                    primaryTypographyProps={ellipsisStyles}
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              <ListItem
                key={folder.key}
                disablePadding
                tw='border-0'
                onClick={() => {
                  if (selectedItem) {
                    const [selectedFolderKey] = selectedItem;

                    if (selectedFolderKey === folder.key) {
                      return;
                    }
                  }

                  closeFolder(folder.key);
                }}
              >
                <ListItemButton selected={true}>
                  <ListItemIcon
                    sx={{
                      color: theme.palette.primary.dark,
                    }}
                  >
                    {folder.icon ?? <FolderOpen />}
                  </ListItemIcon>
                  <ListItemText
                    primary={folder.title}
                    primaryTypographyProps={{
                      ...ellipsisStyles,
                      fontWeight: 'bold',
                      color: theme.palette.primary.dark,
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {openFolders.includes(folder.key) && (
              <List disablePadding dense={true} tw='bg-gray-100'>
                {folder.items
                  ?.filter((item) => hasAccess(`${folder.key}.${item.key}`))
                  .map((item) => (
                    <ListItem
                      key={item.key}
                      disablePadding
                      onClick={() => {
                        if (selectedItem) {
                          const [selectedFolderKey, selectedItemKey] =
                            selectedItem;

                          if (
                            selectedFolderKey === folder.key &&
                            selectedItemKey === item.key
                          ) {
                            return;
                          }
                        }

                        onItemSelection(folder.key, item.key);
                        item.onSelect?.(folder.key, item.key);
                      }}
                    >
                      {(() => {
                        const itemElement = (
                          <ListItemButton>
                            <ListItemText
                              primary={item.title}
                              primaryTypographyProps={ellipsisStyles}
                              tw='pl-14'
                            />
                          </ListItemButton>
                        );

                        if (!selectedItem) {
                          return itemElement;
                        }

                        const [selectedFolderKey, selectedItemKey] =
                          selectedItem;

                        if (
                          folder.key === selectedFolderKey &&
                          item.key === selectedItemKey
                        ) {
                          return (
                            <ListItemButton selected={true}>
                              <ListItemText
                                primary={item.title}
                                primaryTypographyProps={{
                                  ...ellipsisStyles,
                                  fontWeight: 'bold',
                                  color: theme.palette.primary.dark,
                                }}
                                tw='pl-14 truncate'
                              />
                            </ListItemButton>
                          );
                        }

                        return itemElement;
                      })()}
                    </ListItem>
                  ))}
              </List>
            )}
          </React.Fragment>
        ))}
    </List>
  );
};
